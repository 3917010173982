import {useEffect} from 'react';

import {useEvent} from '@lookiero/event';
import {getClient, sendException} from '../../../errors/errorLogger';
import {isStySpSentryProject} from '../../../errors/isStySpSentryProject';

export const useSentryForPayments = (): void => {
  const {subscribe, unsubscribe} = useEvent();

  const notifySentry = (error: unknown): void => {
    const dsn = getClient()?.getDsn();

    if (dsn?.projectId && isStySpSentryProject(dsn?.projectId)) {
      return;
    }

    sendException(error);
  };

  useEffect(() => {
    subscribe({domain: 'PAYMENTS', event: 'MONITORING.ERROR'}, notifySentry);

    return () => unsubscribe({domain: 'PAYMENTS', event: 'MONITORING.ERROR'}, notifySentry);
  }, [subscribe, unsubscribe]);
};
