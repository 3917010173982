import React, {ReactElement} from 'react';
import {PortalProvider as GorhomPortalProvider} from '@gorhom/portal';
import type {PortalProviderProps} from '@gorhom/portal/src/components/portalProvider/types';

const PortalProvider = ({rootHostName, shouldAddRootHost, children}: PortalProviderProps): ReactElement => {
  return (
    <GorhomPortalProvider shouldAddRootHost={shouldAddRootHost} rootHostName={rootHostName}>
      {children}
    </GorhomPortalProvider>
  );
};

export {PortalProvider};
