import React, {ReactElement, useContext, useMemo, useState} from 'react';
import {PaymentsQueryProvider} from '@lookiero/payments-front';

export type PaymentsContextType = {
  showPaymentModalBusy: boolean;
  setShowPaymentModalBusy: (value: boolean) => void;
};

export const PaymentsContext = React.createContext<PaymentsContextType>(null as unknown as PaymentsContextType);

export const PaymentsContextProvider = ({children}: {children: ReactElement[] | ReactElement}): ReactElement => {
  const [showPaymentModalBusy, setShowPaymentModalBusy] = useState(false);

  const value = useMemo(
    () => ({
      showPaymentModalBusy,
      setShowPaymentModalBusy,
    }),
    [showPaymentModalBusy, setShowPaymentModalBusy],
  );
  return (
    <PaymentsContext.Provider value={value}>
      <PaymentsQueryProvider>{children}</PaymentsQueryProvider>
    </PaymentsContext.Provider>
  );
};

export const usePaymentsContext = (): PaymentsContextType => useContext(PaymentsContext);
