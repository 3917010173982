import {
  TrackingEvents as PaymentsTrackingEvents,
  TrackingEvent as PaymentsTrackingEvent,
} from '@lookiero/payments-front';
import {eventManager} from '@application/Event';

export enum PayEvent {
  PAY_PAGE_CTA_PRESSED = 'PayPageCtaPressed',
}

export const paymentEvents = {
  ...PayEvent,
  ...PaymentsTrackingEvents,
} as const;

export type PaymentEvents = (typeof paymentEvents)[keyof typeof paymentEvents];

export const PAY_EVENTS = Object.values(paymentEvents);

export const emitPaymentTrackingEvent = <T extends PaymentsTrackingEvents>(
  event: T,
  payload: PaymentsTrackingEvent<T>,
): void => {
  eventManager.emit(event, payload);
};
